<template lang="pug">
  v-container.pb-0.pt-0.max-width
    v-dialog(v-if="scannerEnabled" v-model="on")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="scanner-btn"
          )
            | Camera Scanner
      v-card(style="width: 480px; height: 360px; max-width: 100%; max-height: 100%;")
        StreamBarcodeReader(@decode="onDecode")
    v-dialog(v-else="!scannerEnabled" v-model="dialog")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          outlined
          color="primary"
          v-bind="attrs"
          v-on="on"
          class="media-btn"
        )
          | video - {{verbiage}}
      v-card(style="width: 640px; height: 360px; max-width: 100%; max-height: 100%;")
        <iframe width="640" height="360" object-fit="cover" :src="video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'

export default {
  name: 'Dialog',
  components: {
    StreamBarcodeReader
  },
  props: {
    scannerEnabled: {
      type: Boolean,
      required: false
    },
    verbiage: {
      type: String,
      required: false
    },
    video: {
      type: String,
      required: false
    },
    onDecode: {
      type: Function,
      required: false
    },
    on: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss">
  .v-dialog {
    width: min-content;
  }
  .media-btn.v-btn--outlined {
    border: 1px solid #f5780c;
  }
  .scanner-btn {
    padding-top: 0 !important
  }
  video {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  .scanner-container {
    height: 100%;
  }
  .scanner-container > div {
    height: 100%;
  }
</style>
